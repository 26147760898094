import {
  SET_SELECTED_CATEGORY_OBJECT,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
} from '../actions/categoryActions';

const initialState = {
  loading: false,
  error: null,
  list: [],
  select: null,
};

const categoryReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
        return { ...state, loading: true, error: null };
  
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };

    case FETCH_CATEGORIES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case SET_SELECTED_CATEGORY_OBJECT:
      return {...state, select: action.payload};
      
    default:
        return state;
  }
};

export default categoryReducer;
