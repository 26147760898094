import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { setFocusDestinationId } from '../actions/destinationActions';
import DestinationCard from './DestinationCard';
import { setSelectedArrStopId } from '../actions/stopsActions';
import { fetchTravelLegs } from '../actions/travelLegsActions';
import { SyncLoader } from 'react-spinners';
import { selectFocusDestinationId, selectSelectCategory, selectSelectedStartStop } from '../selectors/selector';
import { getURL } from '../utils/utils';
import { Loading } from './Loading';

export const SIDEBAR_WIDTH = '500px';


const fetcher = async (page, selectCategory, startStopName, mapSelect
  ) => {

  const destinationParams = {per_page: 10, page: page, 
                             ...(!selectCategory ? Object() : {"categories": selectCategory?.idRelCats.join()}),
                             // ...(category?.cat ? {cat: category.cat} : (category?.subcat ? {subcat: category.subcat} : null)),
                             ...(!startStopName ? Object() : {"start_stop_name": startStopName})
                            };
  const url = getURL(`destinations/${mapSelect}?` + new URLSearchParams(destinationParams))

  try {
    const res = await fetch(url);
    if (!res.ok) {
      throw new Error('Network response was not ok for url=' + url);
    }
    return await res.json();
  } catch (error) {
    console.error('Error fetching data:', error);
    // Return an empty object in case of a failed request
    return {};
  }
}


function DestinationList() {

  const dispatch = useDispatch();
  const selectCategory = useSelector(selectSelectCategory);
  const loading = useSelector((state) => state.destinations.loading);
  const error = useSelector((state) => state.destinations.error);
	const selectedStartStop = useSelector(selectSelectedStartStop);
  const mapSelect = useSelector((state) => state.maps.mapSelect)

  const [ selectDestinations, setSelectDestinations ] = useState([]);
  const [ lastData, setLastData ] = useState({"results": []});
  const focusDestinationId = useSelector(selectFocusDestinationId);


  const fetchNextPageNew = async () => {

    const nextPage = lastData.info ? lastData.info.page + 1 : 1
  
    await fetcher(nextPage, selectCategory, selectedStartStop, mapSelect
      ).then(fetchedData => {
      setLastData(fetchedData);
      
      setSelectDestinations([...selectDestinations, ...fetchedData.results])
      //setResultsLength(fetchedData.info.count)
  
      return lastData;
    })
  }

  const resetInfiniteScroll = () => {
    setSelectDestinations([])
    setLastData({"results": []})
  }

  useEffect(function resetOnCategoryChange () {
      resetInfiniteScroll()
    }, [selectCategory, selectedStartStop, mapSelect
  ])


  const handleCardClick = (destination) => {

    // Dispatch the action to set focusDestinationId when a card is clicked
    dispatch(setFocusDestinationId({value: destination.destination_id, source: "card"}));
    
    if (destination.stop_id_closest) {
       // NOTE: stop_id_closest only defined if a start_stop is defined!
       dispatch(setSelectedArrStopId(destination.stopIdClosest ? {value: destination.stopIdClosest, source: "card"} : null));
       dispatch(fetchTravelLegs(selectedStartStop, destination.stopIdClosest))
    }
  };

  

  return (
    <div
      className="contrast destination-container p-2"
      style={{
        position: 'absolute',
        width: SIDEBAR_WIDTH,
        display: 'flex',
        height: 'calc(100% - 230px)',
        top: '230px',
        width: SIDEBAR_WIDTH,
        borderTop: '1px darkgray solid',
        overflow: 'scroll',
        flexDirection: 'column',
      }}
      id="scrollDiv"
    > 
      <InfiniteScroll
        key={"infinite-scroll"}
        style={{width: "100%", height:"200px"}}
        pageStart={0}
        loadMore={fetchNextPageNew}
        useWindow={false}
        hasMore={lastData.info ? lastData.info.page != lastData.info.pages : true}
        loader={<Loading/>}
      >
        <ul style={{"padding": 0}}>
          {selectDestinations.map((destination) => {
            return <DestinationCard
              style={{"width": SIDEBAR_WIDTH}}
              key={destination.destination_id}
              destinationId={destination.destination_id}
              startStopName={selectedStartStop}
              duration={destination.duration_closest}
              destinationContent={!!destination?.destination_name ? destination : null}
              onDataFetched={() => {}}
              onDestinationCardClick={() => handleCardClick(destination)} // Handle card click
              noCollapse={false}
              simpleCard={true}
              toStopName={destination.name_stop_closest}
            />
            })}
        </ul>
      </InfiniteScroll>
      {/* {loading && <SyncLoader/>} */}
      {error && (
      <p>
        Error:
        {error}
      </p>
      )}
    </div>
  );
}

export default DestinationList;
