import { useSelector } from "react-redux";
import { selectSelectedStartStop } from "../selectors/selector";
import * as L from 'leaflet';


import { MapContainer, TileLayer, CircleMarker, Marker, Popup, Tooltip } from "react-leaflet";
import { divIcon, Point } from "leaflet";

export function DepartureStopPopUp({stopName}) {
    return   <Tooltip closeOnClick={true} >
    <>
      <span style={{fontWeight: "bold"}}>Departure stop:</span>
      <br/>
      Stop: <span style={{fontWeight: "bold"}}>{`${stopName}`}</span>
    </>
    </Tooltip>     
  }

export default function MarkerStartStop({lat, lon, stopName}) {

        return <>
            <CircleMarker 
                center={[lat, lon]}
                pathOptions ={{ 
                    color: "blue",
                    fill: "blue",
                    fillOpacity: 1,
                    stroke: "black", 
                    radius: 5
                }}
            />
            <CircleMarker key={"start-stop-marker"}
                center={[lat, lon]}
                pathOptions={{fill: null, color: "blue", }}
            >
                <DepartureStopPopUp stopName={stopName}/>            
            </CircleMarker>
        </>
    }
