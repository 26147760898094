import axios from 'axios';
import { getURL } from '../utils/utils';

export const FETCH_STOPS_REQUEST = 'FETCH_STOPS_REQUEST';
export const FETCH_STOPS_SUCCESS = 'FETCH_STOPS_SUCCESS';
export const FETCH_STOPS_FAILURE = 'FETCH_STOPS_FAILURE';
export const SET_SELECTED_DEP_STOP_ID = 'SET_SELECTED_DEP_STOP';
export const SET_SELECTED_ARR_STOP_ID = 'SET_SELECTED_ARR_STOP';

export const FETCH_STOP_TYPES_REQUEST = 'FETCH_STOP_TYPES_REQUEST';
export const FETCH_STOP_TYPES_SUCCESS = 'FETCH_STOP_TYPES_SUCCESS';
export const FETCH_STOP_TYPES_FAILURE = 'FETCH_STOP_TYPES_FAILURE';


export const fetchStopsRequest = () => ({
  type: FETCH_STOPS_REQUEST,
});


export const fetchStopsSuccess = (stops) => {
  return { type: FETCH_STOPS_SUCCESS,
    payload: stops };
};

export const fetchStopsFailure = (error) => ({
  type: FETCH_STOPS_FAILURE,
  payload: error,
});

export const setSelectedDepStopId = (data) => ({
  type: SET_SELECTED_DEP_STOP_ID,
  payload: data
});
export const setSelectedArrStopId = (data) => ({
  type: SET_SELECTED_ARR_STOP_ID,
  payload: data
});


export const fetchStopTypesRequest = () => ({
  type: FETCH_STOP_TYPES_REQUEST,
});

export const fetchStopTypesSuccess = (stops) => {
  return { type: FETCH_STOP_TYPES_SUCCESS,
    payload: stops };
};

export const fetchStopTypesFailure = (error) => ({
  type: FETCH_STOP_TYPES_FAILURE,
  payload: error,
});


export const fetchStops = () => {
  return async (dispatch) => {
    dispatch(fetchStopsRequest());
    await axios
      .get(getURL("stops"))
      .then((response) => {
        console.log("fetchStopsSuccess", response.data)
        dispatch(fetchStopsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchStopsFailure(error.message));
      });
  }
}

export const fetchStopTypes =  (mapName) => {
  return async (dispatch) => {
    dispatch(fetchStopTypesRequest());
    await axios
      .get(getURL(`stoptypes/${mapName}`))
      .then((response) => {
        dispatch(fetchStopTypesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchStopTypesFailure(error.message));
      });
  }
}

