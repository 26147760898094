import axios from "axios";
import { fetchDestinationDurationMapSuccess } from "./destinationActions";
import { getURL } from "../utils/utils";

export const SET_MAP_SELECT = 'SET_MAP_SELECT';
// used by destinations (reduced info, but all loaded at once, not paginated)
export const FETCH_MAPS_REQUEST = 'FETCH_MAPS_REQUEST';
export const FETCH_MAPS_SUCCESS = 'FETCH_MAPS_SUCCESS';
export const FETCH_MAPS_FAILURE = 'FETCH_MAPS_FAILURE';


export const fetchMapsRequest = () => ({
  type: FETCH_MAPS_REQUEST,
});

export const fetchMapsSuccess = (data) => {
  return { type: FETCH_MAPS_SUCCESS,
    payload: data };
};

export const fetchMapsFailure = (error) => ({
  type: FETCH_MAPS_FAILURE,
  payload: error,
});

export const setMapSelect = (mapName) => ({
  type: SET_MAP_SELECT,
  payload: mapName,
});

export const fetchMaps = () => {
  return async (dispatch) => {
    dispatch(fetchMapsRequest());

    const url = getURL('maps')
    await axios
      .get(url)
      .then((response) => {
        dispatch(fetchMapsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchMapsFailure(error.message));
      });
  }
}
