import { StopRounded } from "@mui/icons-material";
import { createSelector } from "@reduxjs/toolkit";


export const selectStopList = (state) => state.stops.list
export const selectFocusDestinationId = (state) => state.destinations.focusDestinationId
export const selectSelectedStartStop = (state) => state.stops.selectDepId
export const selectTravelLegsData = (state) => state.travellegs.travellegs
export const selectStopTypesMap = (state) => state.stops.types.map
export const selectMapSelect = (state) => state.maps.mapSelect
export const selectMaps = (state) => state.maps.maps
export const selectRawMapData = (state) => state.destinations.list;
export const selectDurationMapData = (state) => state.destinations.durationMap.list;
export const selectSelectCategory = (state) => state.categories.select;
export const selectMaxDuration = (state) => state.duration.maxDuration;
export const selectSelectArrId = (state) => state.stops.selectArrId

function getArrayIntersection(array1, array2) {
    return array1.filter(element1 => array2.some(element2 => 
        Number(element1) === Number(element2)))

}

function isEmptyArrayIntersection(array1, array2) {
    /* NOTE: using '==' to equal "123" and 123, notably not '.includes'  */
    return !array1.filter(element1 => array2.some(element2 => 
        Number(element1) === Number(element2)))
}

export const selectHasCategoryStopTypesMap = createSelector(
    [selectMaps, selectMapSelect], (maps, mapSelect) => {
        if (!maps || !mapSelect) return;
        return Object.values(maps).filter(x => x.map_name === mapSelect)[0]?.category_stop_types
    }
)

export const selectHasDestinationsMap = createSelector(
    [selectMaps, selectMapSelect], (maps, mapSelect) => {
        if (!maps || !mapSelect) return true;
        const hasDestinations = Object.values(maps).filter(x => x.map_name === mapSelect)[0]?.has_destinations;
        return hasDestinations;
    }
)

export const selectSelectedStartStopData = createSelector(
    [selectStopList, selectSelectedStartStop], (stopList, startStopName) => {
        if (!stopList) return null; 

        const filteredStops = Object.values(stopList).filter(x => x.name === startStopName);

        return (filteredStops.length === 1) ? filteredStops[0] : null
    }
)


/* 
* Results: 
*   - map stop_id to array of stop type names
*/
export const selectFocusRawMapData = createSelector(
    [
        selectRawMapData,
        selectDurationMapData,
        selectSelectCategory,
        selectMaxDuration,
        selectFocusDestinationId,
        selectStopTypesMap,
        selectHasCategoryStopTypesMap
    ],
    (rawMapData,
     durationMapData,
     selectCategory,
     maxDuration,
     focusDestinationId,
     stopTypesMap,
     hasCategoryStopTypesMap
    ) => {


        /* Early return no focus destination */
        if (!focusDestinationId ) {
            return null
        };

        const focusDurationMapData = !durationMapData ? null :
        {
            ...(durationMapData[focusDestinationId.value].mapToStopDuration)
        }

        /* Early return max destination */
        if (!!focusDurationMapData && focusDurationMapData.duration_closest > maxDuration * 60) {
            return null
        };
    
        /* Initialize focus raw map data by slicing rawMapData with focus destination */
        const focusRawMapData = {...rawMapData[focusDestinationId.value]}

        /* Early exit no stop types (i.e. no stops)  */
        if (!focusRawMapData.mapStopToStopTypes) {
            return null
        }


        /* Select category-filtered stops */
        function getCategoryFilteredStops () {
            if (!!hasCategoryStopTypesMap) {
                if (!!selectCategory) {
                    /* 
                    * If the category is not included in the mapStopTypeCategory, the destination 
                    * does not have that category and shouldn't be there.
                    */                    
                    if (!focusRawMapData.mapStopTypeCategory.hasOwnProperty(selectCategory?.idCat)) {
                        return null;
                    }
                    /* If category stops: Get stop list from dedicated map */
                    return focusRawMapData.mapStopTypeCategory[selectCategory?.idCat]
                } else {
                    /* No cat selected => simply all stops in map */
                    return Object.keys(focusRawMapData.mapStopToStopTypes)
                }
            } else {
                /* Else: All stops as defind by stop type map (stops don't have categories) */
                if (!!focusRawMapData.mapStopToStopTypes) {
                    return Object.keys(focusRawMapData.mapStopToStopTypes)
                } else {
                    /* Handle unconnected destination case */
                    return null
                }

            }
        }

        const categoryFilteredStops = getCategoryFilteredStops()?.map(x => Number(x))

        if (!categoryFilteredStops) {
            return 
        }

        const reachableCategoryFilteredStops = !focusDurationMapData 
            ? categoryFilteredStops
            : categoryFilteredStops.filter(
            stopId => focusDurationMapData[stopId] <= maxDuration * 60
        )

        /* Map stop type ids to names: {stopTypeId: ["type1", "type2", ...], ...} */
        const mapStopToStopTypesNames = Object.fromEntries(
            reachableCategoryFilteredStops.map(stopId => {
                return [stopId, focusRawMapData.mapStopToStopTypes[stopId].map(stopTypeId => stopTypesMap[stopTypeId].stop_type_name)]
            }
        ))

        function findClosestStop(obj) {
            return Object.entries(obj).reduce((acc, [idStop, duration]) => {
                if (duration < acc.duration && reachableCategoryFilteredStops.includes(Number(idStop))) {
                    return { idStop, duration };
                }
                return acc;
            }, { idStop: null, duration: Infinity });
        }

        focusRawMapData["mapStopToStopTypesNames"] = mapStopToStopTypesNames
        focusRawMapData["filteredClosestStop"] = !focusDurationMapData ? null : findClosestStop(focusDurationMapData);

        const newFocusRawMapData = {
            "mapStopToStopTypesNames": mapStopToStopTypesNames,
            "filteredClosestStop": !focusDurationMapData ? null : findClosestStop(focusDurationMapData),
            "lat": focusRawMapData.lat,
            "lon": focusRawMapData.lon,
            "destination_name": focusRawMapData.destination_name,
            "mapStopToDuration": focusDurationMapData,
        }

        return newFocusRawMapData;
    }
)

/**
 * Selector: Filters raw map data based on the following criteria:
 * 1. **Duration Data Availability**: If duration data is provided, only destinations with available duration data are kept.
 * 2. **Duration Limit**: Filters out destinations whose closest duration exceeds the provided maximum duration (in minutes).
 * 3. **Category Selection**: If a category is selected, only destinations that match the selected category (or one of its related categories) are kept.
 *
 * If no duration data or category is provided, all raw map data is returned.
 *
 * @param {Object} rawMapData - The raw data of destinations mapped by their ID.
 * @param {Object} durationMapData - The data containing the duration information for each destination (optional).
 * @param {Object} selectCategory - The selected category object, containing the relevant category IDs (optional).
 * @param {number} maxDuration - The maximum allowed duration (in minutes) for filtering destinations (optional).
 *
 * @returns {Object} - The filtered map data as an object, with destination IDs as keys.
 */
export const selectFilteredRawMapData = createSelector(
    [selectRawMapData, selectDurationMapData, selectSelectCategory, selectMaxDuration],
    (rawMapData, durationMapData, selectCategory, maxDuration) => {

    if (!durationMapData && !selectCategory) {
        return rawMapData;
    }
    
    const filteredRawMapDataList = Object.entries(rawMapData).filter(([idDestination, destination]) => {


        /* Filter by duration data available at all for given destination. */
        if (!!durationMapData && !(idDestination in durationMapData)) {
            return false; 
        }

        /* Filter by duration value. */
        if (!!durationMapData 
            && (idDestination in durationMapData)
            && (durationMapData[idDestination].durationClosest > maxDuration * 60)) {
        return false;
        }

        /* Filter by selected category. */
        // const catIntersect = selectCategory?.idRelCats.filter(x => destination.idCategoryArray?.includes(x))
        const catIncluded = destination.idCategoryArray?.includes(selectCategory?.idCat)
        if (!!selectCategory && !catIncluded) {
            return false;
        }

        return true;
    })

    return Object.fromEntries(filteredRawMapDataList)
})




