import {
  FETCH_ISOCHRONE_REQUEST,
  FETCH_ISOCHRONE_SUCCESS,
  FETCH_ISOCHRONE_FAILURE,
  RESET_ISOCHRONE
} from '../actions/isochroneActions';

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const isochroneReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_ISOCHRONE_REQUEST:
        return { ...state, loading: true, error: null };
  
    case FETCH_ISOCHRONE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case FETCH_ISOCHRONE_FAILURE:
      return { ...state, loading: false, error: action.payload };


    case RESET_ISOCHRONE:
      return { ...state, 
        data: initialState.data
      };

    default:
        return state;
  }
};

export default isochroneReducer;
