import React, { Suspense, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Button, DropdownItem } from "react-bootstrap";
import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose, faCaretRight, faXmark } from '@fortawesome/free-solid-svg-icons'

import { MDBDropdownItem } from 'mdb-react-ui-kit';
import { Loading } from "./Loading";

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setSelectedCategoryObject, fetchCategories } from "../actions/categoryActions";
import { selectSelectCategory } from "../selectors/selector";



function SubcategoryItem({ name, count, onClick}) {
    return <MDBDropdownItem style={{paddingLeft: "30px", fontSize: "smaller", display: "flex", justifyContent: "space-between"}}
            onClick={() => onClick(`subcat---${name}`)}           
            key={`subcat---${name}`}
            eventKey={`subcat---${name}`}>
            <div>{name}</div>
            <div>({count})</div>
            </MDBDropdownItem>
}

function CategoryList() {
    
    const dispatch = useDispatch();
    const loadingCategories = useSelector((state) => state.categories.loading, shallowEqual);
    const selectCategory = useSelector(selectSelectCategory);
    const categoriesList = useSelector((state) => state.categories.list);
    const mapSelect = useSelector((state) => state.maps.mapSelect);

    const [ selectCatName, setSelectCatName ] = useState(null);

    useEffect(() => {
        // Fetch dropdown data when the component mounts
        dispatch(fetchCategories(mapSelect));
    }, [dispatch, mapSelect]);

    const handleCategoryClick = ({idRelCats, catName, id}) => {



        if (catName === "___") {
            resetCategorySelect();
            return;
        }

        setSelectCatName(catName)
        dispatch(setSelectedCategoryObject({"idRelCats": idRelCats, "idCat": id}));
      };

    const resetCategorySelect = () => {
        // Dispatch the action to set focusDestinationId when a card is clicked
        setSelectCatName(null);
        dispatch(setSelectedCategoryObject(null));
    };

    return (    
        <div className="m-0 py-0 px-0" 
             style={{display: "flex", 
                     flexDirection: "row",
                     justifyContent: "center"}}>

    {
            loadingCategories 
            ? <Loading/> :
        <>
        <Dropdown className="btn btn-light mr-0 mt-0 p-0 tolowercase"
                  renderMenuOnMount={true} /* <= for hover */
                  style={{flexGrow: "1", overflow:"visible", transition: "all 300ms", zIndex: "1000000"}}
                //   onSelect={
                //     (eventKey, element) => {
                //         handleCategoryClick(eventKey, element)
                //     }
                // }
                  >

            <DropdownButton
                className="tolowercase d-flex text-warning"
                as={ButtonGroup}
                key={"end"}
                id={"dropdown-button-drop-end"}
                drop={"end"}
                variant="light"
                style={{transition: "all 300ms", boxShadow: 0}}
                title={ <span style={{ width: "100%"}}>
                        <div>
                            {
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <div className="tolowercase">
                                { !selectCategory ?
                                    <b>Filter by category</b> :
                                    <div className="tolowercase">Filtered by: <b>{selectCatName}</b></div>
                                }
                                </div>
                                <div>
                                    { !selectCategory ?     
                                    <FontAwesomeIcon icon={faCaretRight}/> : null
                                    }
                                
                                </div>
                            </div>
                            }
                        </div> 
                    </span>
              }>

        {!categoriesList ? null : categoriesList.map((cat) => {
            return <>
                <DropdownItem
                        //onClick={() => onClick(!cat.category.isTotalTotal ? `cat---${cat.category.name}` : null)}           
                    style={{display: "flex", justifyContent: "space-between"}}
                    key={cat.category.name}
                    onClick={() => 
                        {
                            handleCategoryClick({idRelCats: cat.category.id_rel_cats, catName: cat.category.name, id: cat.category.id})
                        }}       
                    >
                        <div><b>{cat.category.name === "___" ? "Total" : cat.category.name}</b></div>
                        <div><b>({cat.category.count})</b></div>
                    </DropdownItem>

                {cat.category.isTotalTotal ? <Dropdown.Divider key={`${cat.category.name}--total`}/> : null}

                {cat.subcategory.map((subcat) => {
                return <DropdownItem 

                style={{paddingLeft: "30px", fontSize: "smaller", display: "flex", justifyContent: "space-between"}}
                onClick={() => 
                    {
                        handleCategoryClick({
                            idRelCats: subcat.id_rel_cats,
                            catName: subcat.name, 
                            id: subcat.id})
                    }}         
                >
                    <div><b>{subcat.name}</b></div>
                    <div><b>({subcat.count})</b></div>
               </DropdownItem>
                })}
                </>
            })}

            </DropdownButton>
        </Dropdown>

        { 
            !selectCategory ? null : 
            <Button variant="light" onClick={() => resetCategorySelect()}>
                <FontAwesomeIcon icon={faXmark}/>
            </Button>
        }
        </>
    }
        </div>
      );

}

export default CategoryList;
