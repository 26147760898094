import axios from 'axios';
import { getURL } from '../utils/utils';

export const FETCH_TRAVELLEGS_REQUEST = 'FETCH_TRAVELLEGS_REQUEST';
export const FETCH_TRAVELLEGS_SUCCESS = 'FETCH_TRAVELLEGS_SUCCESS';
export const FETCH_TRAVELLEGS_FAILURE = 'FETCH_TRAVELLEGS_FAILURE';
export const SET_SELECTED_TRAVELLEG = 'SET_SELECTED_TRAVELLEG';
export const RESET_TRAVELLEGS = 'RESET_TRAVELLEGS';

const DAYTYPECODE_FIXME = 9

export const fetchTravelLegsRequest = () => ({
  type: FETCH_TRAVELLEGS_REQUEST,
});


export const fetchTravelLegsSuccess = (data) => {
  return { type: FETCH_TRAVELLEGS_SUCCESS,
    payload: data };
};

export const fetchTravelLegsFailure = (error) => ({
  type: FETCH_TRAVELLEGS_FAILURE,
  payload: error,
});

export const setSelectedTravelLeg = (data) => (
  {
  type: SET_SELECTED_TRAVELLEG,
  payload: data
});

export const resetTravelLegs = () => {
  return {
  "type": RESET_TRAVELLEGS,
  "payload": null
}};

export const fetchTravelLegs = (fromStop, toStop) => {
  return async (dispatch) => {
    dispatch(fetchTravelLegsRequest());
    await axios
      .get(getURL(`travellegs/${DAYTYPECODE_FIXME}/${fromStop}/${toStop}`))
      .then((response) => {
        console.log("travellegs fetchStopsSuccess", response.data)
        dispatch(fetchTravelLegsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchTravelLegsFailure(error.message));
      });
  }
}
