import {
  SET_FOCUS_DESTINATION_ID,
  FETCH_MAPDESTINATIONS_FAILURE,
  FETCH_MAPDESTINATIONS_REQUEST,
  FETCH_MAPDESTINATIONS_SUCCESS,
  FETCH_DESTINATIONSDURATIONMAP_FAILURE,
  FETCH_DESTINATIONSDURATIONMAP_REQUEST,
  FETCH_DESTINATIONSDURATIONMAP_SUCCESS,
  RESET_DESTINATIONSDURATIONMAP
  // SET_FOCUS_DESTINATION_OBJECT,
} from '../actions/destinationActions';

const initialState = {
  focusDestinationId: null,
  list: null,
  loading: false,
  error: null,
  durationMap: {
    list: null,
    loading: false,
    error: false,
  }
};

const destinationReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_MAPDESTINATIONS_REQUEST:
        return { ...state, loading: true, error: null };
  
    case FETCH_MAPDESTINATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };

    case FETCH_MAPDESTINATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    /* DURATION MAP*/
      
    case FETCH_DESTINATIONSDURATIONMAP_REQUEST:
        return { ...state,
          durationMap: {...state.durationMap, loading: true, error: null}
           };
  
    case FETCH_DESTINATIONSDURATIONMAP_SUCCESS:
      console.log("FETCH_DESTINATIONSDURATIONMAP_SUCCESS", action.payload )
      return {
        ...state, durationMap: {...state.durationMap, list: action.payload, loading: false}
      };

    case FETCH_DESTINATIONSDURATIONMAP_FAILURE:
      return { ...state, 
        durationMap: {...state.durationMap, loading: false, error: action.payload}
         };

    case RESET_DESTINATIONSDURATIONMAP:
      return { ...state, 
        durationMap: initialState.durationMap
         };

    /* FOCUS*/

    case SET_FOCUS_DESTINATION_ID:
        return { ...state, focusDestinationId: action.payload}

    default:
        return state;
  }
};

export default destinationReducer;
