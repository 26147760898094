import React, { useState, useEffect, useCallback } from 'react';
import { MDBInput } from 'mdb-react-ui-kit';
import { MDBListGroupItem, MDBListGroup } from "mdb-react-ui-kit";
import FuzzySet from 'fuzzyset'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { Loading } from './Loading';
import { PropagateLoader } from 'react-spinners';

import useVisible from '../hooks/useVisible';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
/* import Tooltip from 'react-bootstrap/Tooltip';
 */import 'bootstrap/dist/css/bootstrap.min.css'; /* notably for tooltip arrows! */
import { setSelectedDepStopId } from '../actions/stopsActions';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { fetchDestinationDurationMap, resetDestinationDurationMap } from '../actions/destinationActions';
import { fetchTravelLegs, resetTravelLegs, setSelectedTravelLeg } from '../actions/travelLegsActions';
import { selectHasDestinationsMap, selectSelectArrId } from '../selectors/selector';
import { fetchIsochroneMap, resetIsochrone } from '../actions/isochroneActions';

import Tooltip from '@mui/joy/Tooltip';/* 

function simplifyString (string) {
    const replacePairs = [["-", ""], [",", ""], ["é", ""], ["(", ""], [")", ""],
                          ["'", ""], [".", ""], ["â", "a"], ["ô", "o"], ["è", "e"],
                          ["ê", "e"], ["ë", "e"], ["à", "a"],["/", ""],["ä", "a"],
                          ["ü", "u"], ["ò", "o"], ["+", ""], ["î", "i"], ["û", "u"],
                          ["ï", "i"], ["ç", "c"], ["ö", "o"], ["ì", "i"], ["ó", "o"],
                          ["ù", "u"], ["&", ""], ["ß", "s"], [" ", ""], ["-", ""], [" ", ""],
                          [" ", ""]]
    let stringNew = string.toLowerCase();
    replacePairs.forEach(([from, to]) => stringNew = stringNew.replaceAll(from, to))
    return stringNew;
}
 */

const StyledMDBListGroupItem = styled(MDBListGroupItem)`
  &:hover {
    cursor: pointer!important;
  }
  color: red
`;

const StyledMDBListGroup = styled(MDBListGroup)`
&:hover {
    cursor: pointer!important;
  }
  color: red

`;

function simplifyString (string) {
    var combining = /[\u0300-\u036F +-]/g; 

    return string.normalize('NFKD').toLowerCase().replace(combining, '');
}


function Autocomplete () {


    const dispatch = useDispatch();

    const stopsLoading = useSelector((state) => state.stops.loading)
    const stopList = useSelector((state) => state.stops.list)
    const selectArrId = useSelector(selectSelectArrId)
    const mapSelect = useSelector((state) => state.maps.mapSelect)
    const hasDestinationsMap = useSelector(selectHasDestinationsMap);

    useEffect(() => {
        console.log("stopsLoading CHANGED", stopsLoading)
    }, [stopsLoading])

    const [ content, setContent ] = useState(null);
    const [ fuzzySet, setFuzzySet ] = useState(null);
    const [ suggestList, setSuggestList ] = useState(null);
    const [ tooltipText, setTooltipText ] = useState("Select public transport stop to filter by travel duration.")
    const { isVisible, setIsVisible } = useVisible(true);
    const [ showCloseButton, setShowCloseButton] = useState(false);
    const [ unknownDestination, setUnknownDestination] = useState(false);

    const [ simplifiedStringMap, setSimplifiedStringMap ] = useState(null)


    const inputRef = useRef(null);

    const anyKeyFunction = useCallback((event) => {
        //console.log("event", event)
    }, [])

    const label ="Travel from ..."

    const handleItemSelect = (content) =>  {
        console.log("handleItemSelect", content, selectArrId)
        dispatch(setSelectedDepStopId(content))
        console.log("AUTOCOMPLETE hasDestinationsMap", hasDestinationsMap);
        if (hasDestinationsMap) {
            dispatch(fetchDestinationDurationMap(mapSelect, content))
        } else {
            dispatch(fetchIsochroneMap(mapSelect, content))
        }

        if (!!selectArrId) {
            dispatch(fetchTravelLegs(content, selectArrId.value))
        }
    }

    useEffect(function generateSimplifiedStringMap() {

        if (!!simplifiedStringMap) { return }
        if (!stopList) { setSimplifiedStringMap(null); return; }

        const newSimplifiedStopMap = Object.fromEntries(
                Object.values(stopList).sort().map((x) => {
                            return [simplifyString(x.name), x.name]
                        }));

        setSimplifiedStringMap(newSimplifiedStopMap);

    }, [stopList])

    function handleFocus(){
        inputRef.current?.blur(); // removing focus
      }
    

    const enterFunction = useCallback((event) => {
        console.log("event.keyCode", event.keyCode)
        if(event.keyCode === 13) {  // pressed enter key

            setIsVisible(false);

            if (Object.values(stopList).includes(content)) {
                handleItemSelect(content)
                setShowCloseButton(true);
                setUnknownDestination(false);
            } else {
                setTooltipText(`Please select a valid location.${!!content ? ` "${content}" not found.` : ""}`)
                setUnknownDestination(true)
            }
            
            handleFocus()
          }
    }, [content])


    const escFunction = useCallback((event) => {
        if(event.keyCode === 27) {
          setIsVisible(false);
        }
      }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        document.addEventListener("keydown", enterFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
            document.removeEventListener("keydown", enterFunction, false);

        };
    }, [content]);


    useEffect(function makeFuzzySet () {
        if (!simplifiedStringMap) return;

        setFuzzySet(FuzzySet(Object.keys(simplifiedStringMap)
        .sort((a, b) => a.length - b.length), false));

    }, [simplifiedStringMap])
    
    const itemClick = (e) => {

        setIsVisible(false);

        setTimeout(() => {  // prioritizes previous function call 
            console.log("e.target.textContent", e.target.textContent)
            
            setContent(e.target.textContent)
            handleItemSelect(e.target.textContent)
            setShowCloseButton(true);
        })
    }


    function getMatches(string) {

        if (!string) {
            return [];
        }

        const resultList = [];
        const simpleString = simplifyString(string);

        /* Add exact match */
        if (simpleString in simplifiedStringMap) {
            resultList.push(simpleString)
        }

        /* Add stops starting with string */
        Object.keys(simplifiedStringMap).forEach((x) => {
            if ((!resultList.includes(x)) 
                && (x.startsWith(simpleString))
                && (resultList.length <= 15) ){
                    resultList.push(x);
            }
        })

        const fuzzySetSelect = fuzzySet?.get(simpleString);

        if (!!fuzzySetSelect) {
            fuzzySetSelect.filter(([index, value]) => !resultList.includes(value))
                .forEach(([index, value]) => resultList.push(value))
        }

        return resultList.slice(0, 15).map((stop) => simplifiedStringMap[stop]);
    }

    useEffect(() => {
        //console.log("-->content", content)
    }, [content])

    function inputInput (e) {
        setContent(e.target.value);

        if (e.target.value.length < 2) {
            setIsVisible(false);
            return;
        }


        if (e.target.value.length >= 2) {
            setSuggestList(getMatches(e.target.value))
            setIsVisible(true);
        }
    }

    useEffect(function resetOnMapChange () {
        handleClose()
    }, [mapSelect])

    function handleClose() {
        setContent("");
        setTooltipText("");
        setShowCloseButton(false);
        dispatch(resetDestinationDurationMap());  // not checking hasDestinations here
        dispatch(resetIsochrone());  // not checking hasDestinations here
        dispatch(resetTravelLegs(null));
        handleItemSelect(null);
    }

    return (
        <div> {/*Surrounding div necessary for popup !!! */}
    <>



        <div> {/* div necessary for popup !!! */}
        {!!stopsLoading
         ? <Loading/> :


<Tooltip title={!tooltipText ? tooltipText : tooltipText} open={true} color={unknownDestination ? "danger" : "primary"} leaveDelay={500} sx={{}} placement="right" arrow>
    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}> {/* div necessary for popup !!! */}
        <MDBInput label={label}
                  value={content || ""}
                  ref={inputRef}
                  onClick={(e) =>  {setTooltipText(null);
                                    inputInput(e);}}
                  onInput={(e) => {inputInput(e);}}
                  type='text'
                  style={{'radius': "0"}}>
        </MDBInput>
        { !showCloseButton ?
        null : <Button variant="light" onClick={handleClose}>
                <FontAwesomeIcon icon={faXmark}/>
        </Button>}
    </div>
    </Tooltip>
}
</div>
                    
        { isVisible ?
            <MDBListGroup
                style={{ width: '600px', 
                         overflowX: "hidden", 
                         boxShadow: "0 2px 5px 0 rgba(0,0,0,.2),0 2px 10px 0 rgba(0,0,0,.1)",
                         marginTop: "5px", 
                         maxHeight: "200px", 
                         overflowY: "scroll",  
                         position: "absolute", 
                         zIndex: 1000000 
                        }}>
                { suggestList?.map((stopName) => (
                    <MDBListGroupItem style={{ border: "none", textAlign: "left"}}
                                      key={stopName} 
                                      onClick={itemClick}>
                        {stopName}
                    </MDBListGroupItem>))
                }
            </MDBListGroup> 
        : <></>
        }
        </>
      </div>
      );
}

export {Autocomplete, simplifyString };