

import axios from "axios";
import { getURL } from "../utils/utils";


export const FETCH_ISOCHRONE_REQUEST = 'FETCH_ISOCHRONE_REQUEST';
export const FETCH_ISOCHRONE_SUCCESS = 'FETCH_ISOCHRONE_SUCCESS';
export const FETCH_ISOCHRONE_FAILURE = 'FETCH_ISOCHRONE_FAILURE';
export const RESET_ISOCHRONE = 'RESET_ISOCHRONE';



export const fetchIsochroneRequest = () => ({
    type: FETCH_ISOCHRONE_REQUEST,
  });
  
  export const fetchIsochroneSuccess = (data) => {
    return { type: FETCH_ISOCHRONE_SUCCESS,
      payload: data };
  };
  
  export const fetchIsochroneFailure = (error) => ({
    type: FETCH_ISOCHRONE_FAILURE,
    payload: error,
  });

  export const resetIsochrone = () => ({
    type: RESET_ISOCHRONE,
  });
  
  export const fetchIsochroneMap = (mapSelect, depStopName) => {
    return (dispatch) => {
      dispatch(fetchIsochroneRequest());
  
     // const baseUrl = getURL(`isochrone/${mapSelect}?`)
      const baseUrl = getURL(`isochrone/?`)
      const url = baseUrl + new URLSearchParams({ "start-stop-name": depStopName, "daytype-code": 9, "duration": 60 * 60 * 24})
      axios
        .get(url)
        .then((response) => {
          dispatch(fetchIsochroneSuccess(response.data));
        })
        .catch((error) => {
          dispatch(fetchIsochroneFailure(error.message));
        });
    }
  }
  