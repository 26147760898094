import {
  FETCH_STOPS_REQUEST,
  FETCH_STOPS_SUCCESS,
  FETCH_STOPS_FAILURE,
  SET_SELECTED_ARR_STOP_ID,
  SET_SELECTED_DEP_STOP_ID,
  FETCH_STOP_TYPES_FAILURE,
  FETCH_STOP_TYPES_REQUEST,
  FETCH_STOP_TYPES_SUCCESS
  } from '../actions/stopsActions';

const initialState = {
  loading: false,
  error: null,
  list: null,
  selectDepId: null,
  selectArrId: null,
  types: {
    loading: false,
    error: null,
    map: null
  }
};

const stopsReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_STOPS_REQUEST:
        return { ...state, loading: true, error: null };
  
    case FETCH_STOPS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };

    case FETCH_STOPS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case SET_SELECTED_DEP_STOP_ID:
      return {...state, selectDepId: action.payload};

    case SET_SELECTED_ARR_STOP_ID:
      return {...state, selectArrId: action.payload};

    case FETCH_STOP_TYPES_REQUEST:
        return { ...state, types: { ...state.types, loading: true, error: null }};

    case FETCH_STOP_TYPES_SUCCESS:
      return {
        ...state,
        types: { ...state.types, 
                loading: false,
                map: action.payload
        }
      };

    case FETCH_STOP_TYPES_FAILURE:
      return { ...state, 
        types: { ...state.types, 
          loading: false, error: action.payload} };

    default:
        return state;
  }
};

export default stopsReducer;
