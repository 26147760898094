
import DestinationCard from "./DestinationCard"
import { MDBAccordion, MDBCollapse, MDBAccordionItem } from "mdb-react-ui-kit"
import { TravelLegsList } from "./Travelleg"
import { useEffect, useState } from "react"

import Offcanvas from 'react-bootstrap/Offcanvas';
import { useDispatch, useSelector } from "react-redux"
import { selectDurationMapData, selectTravelLegsData } from "../selectors/selector";

function DestinationInfoPaneContent({
        focusDestinationId,
        focusDestinationContent,
    }) {


    const selectedStartStop = useSelector((state) => state.stops.selectDepId)

    const [ destinationTitle, setDestinationTitle ] = useState(null)

	const travelLegsData = useSelector(selectTravelLegsData);


    return !focusDestinationId ? null : <>
    <DestinationCard 
        key={`focuscard-${focusDestinationId}`}
        noCollapse={true}
        style={{ flexGrow: 0, backgroundColor: "g" }}
        startStopName={ selectedStartStop }
        toStopName={"n/a"}
        duration={travelLegsData?.distance}
        destinationId={focusDestinationId.value}
    />

</>


    return !focusDestinationId ? null : <>
    <h4>{destinationTitle}</h4>

    <MDBAccordion initialActive={1} alwaysOpen>

    <MDBAccordionItem dangerouslySetExpanded={true} collapseId={1} headerTitle='Destination Info'>
        <div className="info" style={{display: "flex",
             alignItems: "center",
             flexDirection: "column", 
             maxHeight: "fit-content"
            }}
             >
             <DestinationCard 
                key={`focuscard-${focusDestinationId}`}
                noCollapse={true}
                style={{ flexGrow: 0, backgroundColor: "g" }}
                startStopName={ selectedStartStop }
                toStopName={"n/a"}
                duration={"n/a"}
                destinationId={focusDestinationId.value}
            />
        </div>
      </MDBAccordionItem>
      </MDBAccordion>


      {!selectedStartStop ? null :

      <MDBAccordion initialActive={2} alwaysOpen>
        <MDBAccordionItem eventKey="1" collapseId={2} 
                headerTitle={
                <>
                 {null //   {`Fastest trip from ${startStop}`}<br/>{`(${minutesToTimeStr(destinationObject?.duration_closest)})`}
                }</>}>
            <div className="travellegslist-wrapper" style={{maxHeight: "fit-content"}}>
                <MDBCollapse show={true} style={{width: "100%"}}>
                    <TravelLegsList style={{height: "100%"}}/>
                </MDBCollapse>
            </div>
        </MDBAccordionItem>
      </MDBAccordion>
    }
    </>
}

function DestinationOverlay () {
    
    const dispatch = useDispatch();

    const durationMapData = useSelector(selectDurationMapData);
    const focusDestinationId = useSelector((state) => state.destinations.focusDestinationId);
	const travelLegsData = useSelector((state) => state.travellegs.travellegs)

    const [durationClosest, setDurationClosest] = useState(null)
    const [closed, setClosed] = useState(false)


    const handleClose = () => setClosed(true);
    const handleShow = () => setClosed(false);
  
    useEffect(function calcDurationClosest() {
        console.log("DESTINATIONOVERLAY focusDestinationId", focusDestinationId)
    }, [focusDestinationId])


    useEffect(function calcDurationClosest() {
        setDurationClosest(
            !durationMapData ? null : durationMapData[focusDestinationId?.id_destination]?.duration_closest
        )
    }, [durationMapData, focusDestinationId])


    useEffect(function calcClosed() {
        setClosed(focusDestinationId === null)
    }, [durationMapData, focusDestinationId])


    useEffect(() => {
        console.log("DESTINATIONOVERLAY travelLegsData", travelLegsData)
    }, [travelLegsData])

    return <>{ closed ? <></> : 
        <div                         style={{
            position: "absolute",
            overflow: "clip",
            top: "28px",
            right: "10px",
            width: "300px",
            zIndex: "1000",
            maxHeight: "calc(100% - 30px)",
            overflowY: "scroll"
        }}
>          
                <DestinationInfoPaneContent
                    className="destination-info-pane-content"
                    focusDestinationId={focusDestinationId}
                    focusDestinationContent={focusDestinationId?.content}
                    travelLegsData={travelLegsData}
                />
        </div>}
</>
}

export default DestinationOverlay;