import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setMaxDuration } from '../actions/durationActions';
import {minutesToTimeStr} from '../utils/utils';
import { Slider } from '@mui/material';
import { selectFocusRawMapData, selectMaxDuration, selectSelectArrId } from '../selectors/selector';
import { setSelectedArrStopId } from '../actions/stopsActions';

export default function DurationSelect() {
  const dispatch = useDispatch();
  const maxDuration = useSelector(selectMaxDuration);
  const selectArrId = useSelector(selectSelectArrId);
  const focusRawMapData = useSelector(selectFocusRawMapData);


  useEffect(function snapArrStopToClosest(){
    if (!focusRawMapData || !focusRawMapData.mapStopToDuration) {
      dispatch(setSelectedArrStopId(null));
      return;
    }

    /* If current arr id is not in map stop to duration, set to closest stop */
    if (!Object.keys(focusRawMapData.mapStopToStopTypesNames).some(x => x == selectArrId?.value)) {
      dispatch(setSelectedArrStopId({value: focusRawMapData.filteredClosestStop?.idStop, source: "snap"}))
    }

  }, [focusRawMapData])


  
  

  const changeSelection = (e, newMaxDuration) => {
    dispatch(setMaxDuration(newMaxDuration));
  }

  return <div style={{display: "flex", flexDirection: "column", alignItems: "baseline"}}>

      <span className='pl-1'>Max. travel duration: {minutesToTimeStr(maxDuration ? maxDuration : 120)}</span>
      <Slider style={{gridColumn: "1 / 1", gridRow: "1 / 1"}}
                  defaultValue={120}
                  valueLabelFormat={value => minutesToTimeStr(value)}
                  valueLabelDisplay="auto"
                  id='customRange'
                  label=''
                //  onChange={(changeSelection)}
                  onChangeCommitted={(event, value) => changeSelection(event, value)}
                  // onKeyUp={changeSelection}
                  min={0}
                  max={60 * 12}
              />

    </div>

}