import { Marker, Tooltip, Popup } from 'react-leaflet';
import { blueIcon, redIcon, greenSquareIcon, busIcon, yellowSquareIcon } from './markers';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedArrStopId } from '../actions/stopsActions';
import { fetchTravelLegs } from '../actions/travelLegsActions';
import { setFocusDestinationId } from '../actions/destinationActions';
import 'react-leaflet';
import { getStringSVG } from '../utils/iconUtils';
import L from 'leaflet'; // Import Leaflet
import { useEffect, useState } from 'react';

import iconBus from '../assets/icon_bus.svg';
import iconBoat from '../assets/icon_boat.svg';

function DestinationPopUp({destinationName}) {
  return <Popup closeOnClick={true} >
  <>
    <span style={{fontWeight: "bold"}}>{`${destinationName}`}</span>
  </>
  </Popup>
}

export function StopPopUp({stopName, stopId, destinationName}) {
  return   <Tooltip closeOnClick={true} >
  <>
    <span style={{fontWeight: "bold"}}>{`${destinationName}`}</span>
    <br/>
    Stop: <span style={{fontWeight: "bold"}}>{`${stopName}`}</span>
    <br/>
    Stop ID: <span style={{fontWeight: "bold"}}>{`${stopId}`}</span>
  </>
  </Tooltip>     
}

export function StopTypesMarker({
    stopTypesList, position, stopId, stopName, destinationName, onClick}) {

  // const stopTypesList = [
  //   "Train", "Road", "Urban", "Other", "Boat", "Cable car/Funicular", "Sledding", "Cross country", "Skiing and snowboarding", "Winter hike and snowshoeing"];
    
  const nIcons = stopTypesList.length;

  if (nIcons === 0) return null;

  const sizeIcon = 25;
  const marginIcon = 2.75;
 
  function getIcon() { 

    return L.divIcon({className: "div-icon",
    iconAnchor: [0.5 * sizeIcon * nIcons + 0.5 * (nIcons - 1) * marginIcon, sizeIcon * 0.5 ],
    html: `
    <div class="marker-icon-stop-categories">
        ${stopTypesList.map(stopType => getStringSVG(stopType, true, sizeIcon)).join("")}
    </div>`
    });
  }

  const newMarker = <Marker
      key={stopName}
      position={position}
      icon={nIcons === 0 ? greenSquareIcon : getIcon()}
      onClick={onClick}
      eventHandlers={{click: onClick}}
      style={{zIndex: 10000000}}>

        <StopPopUp stopName={stopName} stopId={stopId} destinationName={destinationName}/>
      </Marker>  

      return newMarker
}

export default function FocusMarker({stopTypesMap,
                                     selectCategory,
                                     selectedStartStop,  //for dispatch(fetchTravelLegs...
                                     stopList,
                                     focusRawMapData}) {

    const dispatch = useDispatch();

    if (!focusRawMapData) {return null};

    const {lon, lat, destination_name, mapStopToStopTypesNames} = focusRawMapData; 

    function handleClick (idStop) {
      dispatch(setSelectedArrStopId({value: Number(idStop), source: "stopMarker"}))
      dispatch(fetchTravelLegs(selectedStartStop, idStop))
    }

    return <>
        <Marker position={[lat, lon]}
          icon={blueIcon}
          eventHandlers={{click: () => console.log("CLICK")}}
          style={{zIndex: "1000000!important", yScale: 10, width: "fit-content!important"}}>
           <DestinationPopUp destinationName={destination_name}/>
        </Marker>


        {!mapStopToStopTypesNames ? null : Object.entries(mapStopToStopTypesNames)
          .map(([idStop, stopTypesNamesList]) => {

          const {lat, lon, name} = stopList[idStop]
//
//          /* TODO1: stopTypesNamesList SHOULD BE PROVIDED BY SELECTOR! */
//          const stopTypesNamesList = stopTypesList.map(stopTypeId => {
//            return !stopTypeId ? null : stopTypesMap[stopTypeId].stop_type_name
//          })
//

          return <StopTypesMarker 
                  stopTypesList={stopTypesNamesList}
                  onClick={() => handleClick(idStop)}
                  stopName={name}
                  stopId={idStop}
                  destinationName={destination_name}
                  position={[lat, lon]}/>
          }
        )}
    </>
  }

