import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import borderGeoJSON from "../static/switzerland_detailed.json"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import "leaflet-boundary-canvas";
import { useSelector } from "react-redux";

export default function LeafletCanvasMarker2() {
  const map = useMap();
  const mapSelect = useSelector((state) => state.maps.mapSelect)


  useEffect(() => {
    if (!map) return;

      const cutout = false;

      // MAP OVERVIEW: http://leaflet-extras.github.io/leaflet-providers/preview/

//       const tileUrl = 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}.png';
      const tileUrl = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
      const attrStr = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      const baseLayer = L.tileLayer(tileUrl, {opacity: 0.4, className: "grayscale-tile-layer"} )

      const countryLayer = L.TileLayer.boundaryCanvas(tileUrl, {boundary: borderGeoJSON, attribution: attrStr, 
          className: "grayscale-tile-layer"
        });

    
      map.addLayer(baseLayer);
      map.addLayer(countryLayer);

    if (mapSelect === "ch_winter") {
      const openSnowMapLayer = L.TileLayer.boundaryCanvas("https://tiles.opensnowmap.org/pistes/{z}/{x}/{y}.png",
                {boundary: borderGeoJSON, attribution: '&copy; <a href="http://www.opensnowmap.org">opensnowmap</a>'})
      map.addLayer(openSnowMapLayer);
    }

    // Cleanup function when the component unmounts
    return () => {
      // Remove all layers
      map.eachLayer((layer) => {
        if ("_url" in layer) {  /*Important: Not our own marker layers! */
           map.removeLayer(layer);
        }
      });
    };

  }, [map, mapSelect]);



  return null;
}
