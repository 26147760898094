import {
  FETCH_MAPS_FAILURE,
  FETCH_MAPS_REQUEST,
  FETCH_MAPS_SUCCESS,
  SET_MAP_SELECT
  // SET_FOCUS_DESTINATION_OBJECT,
} from '../actions/mapSelectActions';

const initialState = {
  maps: null,
  loading: false,
  error: null,
  mapSelect: "ch_destinations",
  mapGrayscale: false
};

const mapsReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case FETCH_MAPS_REQUEST:
        return { ...state, loading: true, error: null };
  
    case FETCH_MAPS_SUCCESS:
      return {
        ...state,
        loading: false,
        maps: action.payload,
      };

    case FETCH_MAPS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    /* SELECTION */

    case SET_MAP_SELECT:
        return { ...state, mapSelect: action.payload}

    default:
        return state;
  }
};

export default mapsReducer;
