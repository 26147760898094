import * as L from 'leaflet';

const markerProperties = {
    iconSize: [25, 25],
    iconAnchor: [12.5, 25],
    tooltipAnchor: [0, -25]
}


var redIcon = L.icon({
    iconUrl: "redmarker.svg",
    ...markerProperties
});


var blueIcon = L.icon({
    iconUrl: "bluemarker.svg",
    ...markerProperties
  });


const smallMarkerProperties = {
    iconSize: [10, 10],
    iconAnchor: [5, 5],
    tooltipAnchor: [0, -10]
}



var greenSquareIcon = L.icon({
    iconUrl: "greensquare.svg",
    ...smallMarkerProperties
});

var yellowSquareIcon = L.icon({
    iconUrl: "greensquare.svg",
    ...smallMarkerProperties
  });


  const trafficMarkerProperties = {
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    tooltipAnchor: [0, -20]
}

var busIcon = L.icon({
    iconUrl: "icon_bus.svg",
    ...trafficMarkerProperties
});



export { redIcon, busIcon, blueIcon, greenSquareIcon, yellowSquareIcon }