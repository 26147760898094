import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonWalking, faTrainTram, faTrain, faBus, faCableCar } from '@fortawesome/free-solid-svg-icons'

import {minutesToTimeStr} from '../utils/utils';
import '../styles/TravelList.css';
import { useSelector } from 'react-redux';
import { selectTravelLegsData } from '../selectors/selector';
import { useEffect } from 'react';
import Typography from '@mui/joy/Typography';
import ImageIcon from '@mui/icons-material/Image';
import AspectRatio from '@mui/joy/AspectRatio';


function TravellegWalk({travellegIndex, onMouseOver, start, stop, highlight}) {

	
	return <div key={travellegIndex} 
				type="button"
				onMouseOver={onMouseOver}
				style={{width: "83%"}}
				className={`btn btn-${(highlight) ? "primary" : "light"} btn-rounded travelleg`}>

		<div className={"travelleg-content"}>
			Walk from <strong>{ start }</strong> to <strong>{ stop }</strong>
		</div>

		<div className={"icon-dot icon-dot-walk"}>
			{/* <img className={"icons-legs"} src={iconWalk}/> */}
			<FontAwesomeIcon className={"icons-legs"} icon={faPersonWalking}/>
		</div>

	</div>
}


function TravellegOther({ travellegIndex, onMouseOver, 
					      start, highlight, stop, stopsSequence,
						  routeName, routeCategory, travelTimeStr}) {

	const iconMap = {
		//train: faTrainTram,
		train: faTrain,
		funicular: faTrainTram,
		cable: faCableCar,
		bus: faBus,
	}


	return <button key={ travellegIndex } 
				type="button"
				onMouseOver={onMouseOver}
				style={{ width: "83%", }}
				className={`btn btn-${(highlight) ? "primary" : "light"} btn-rounded travelleg`}>
		<div className={"travelleg-content"}>
			<table>
				<tr>
					<td><div className={`dot ${highlight ? "highlight" : ""}`}></div></td><td><b>{ start } {/* {props.startTime} */}</b></td>
				</tr>
				<tr><td><div className="dot-connect"></div></td><td>{ routeName } ({ travelTimeStr })</td></tr>
				<tr>
					<td><div className={`dot ${highlight ? "highlight" : ""}`}></div></td><td><b>{ stop } {/* { props.stopTime } */}</b></td>
				</tr>
			</table>
		</div>	
		{/* <div className={`icon-dot icon-dot-${ routeCategory }`}>
			<FontAwesomeIcon className={"icons-legs"} icon={iconMap[routeCategory]}/>
		</div> */}
	</button>	
}


function TravelLegsList () {
	const travelLegsData = useSelector(selectTravelLegsData)


	return !travelLegsData ? null : 
		<div id="content-selected-trip" className="column">
			<div style={{maxHeight: "300px", overflowY: "scroll", fontSize: "small"}}>

				{Object.entries(travelLegsData.data).map(([iLeg, dataLeg], _) => {

					return dataLeg.route_name === "walk" 
						? <TravellegWalk key={iLeg} travellegIndex={iLeg} 
										start={dataLeg.start_name} stop={dataLeg.stop_name}/>
						: <TravellegOther routeCategory={dataLeg.route_category}
										travelTimeStr={minutesToTimeStr(dataLeg.travel_duration)}
										routeName={dataLeg.route_name} 
										key={iLeg}
										travellegIndex={iLeg}
										stopsSequence={dataLeg.stopsSequence}
										highlight={true}
										start={dataLeg.start_name} stop={dataLeg.stop_name}/>
										})}
        </div>
	</div>
}



export { TravelLegsList };